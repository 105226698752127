import * as React from 'react';
import {
  AboutWrapper,
  AboutPageTitle,
  AboutGrid,
} from './style';
import { OurGamesPageTitle, OurGamesWrapper } from '../ourgames/style';
import { OurTeamImage, OurTeamItem, OurTeamSubText, OurTeamText, OurTeamWrapper } from '../home/style';
import defaultProfileImage from '../../images/user.png';
import about1 from '../../images/hr_about_1.jpg';
import about2 from '../../images/hr_about_2.jpg';
import about3 from '../../images/hr_about_3.jpg';
import about4 from '../../images/hr_about_4.jpg';
import about5 from '../../images/hr_about_5.jpg';
import about6 from '../../images/hr_about_6.jpg';
import emiliano from '../../images/emiliano.png';
import francesco from '../../images/francesco.png';
import gavin from '../../images/gavin.png';
import yannis from '../../images/yannis.png';
import alessandro from '../../images/alessandro.png';
import raimo from '../../images/raimo.png';
import { Link } from 'gatsby';

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About Hammer & Ravens</h2>
        <AboutGrid>

        <img className="grid-item about-image" src={about4}/>
        <p className="grid-item">We are Hammer & Ravens, an indie game studio based in Tallinn, Estonia. The studio is operated by a madman and manned by a band of pirates, vagabonds, and rogues. What defines our games is tongue-in-cheek realism and mechanics that are challenging, but always fun!</p>

        <p className="grid-item">In early 2018 (and while developing Empires in Ruins) we released Razerwire:Nanowars, a bite-sized frantic bullet hell space game that strangely reminds you of the "cooties"... By 2021, Razerwire:Nanowars was also made available on Xbox and Nintendo Switch.</p>
        <img className="grid-item about-image" src={about5}/>

        <img className="grid-item about-image" src={about2}/>
        <p className="grid-item">In February 2020, Empires in Ruins was released in Early Access. Empires in Ruins is a daring TD/4X mashup staged in a grim world where the power-hungry and corrupt take on our antihero, Sgt. Hans Heimer. Empires in Ruins left Early Access in March '21. </p>


        <p className="grid-item">In December 2020, we took our first stab into publishing, with Vikerlane's Headbangers in Holiday Hell. A game that smells like the '90s, feels like the '90s, and makes you question the motives of that bearded guy in the red costume. </p>
        <img className="grid-item about-image" src={about3}/>

        <img className="grid-item about-image" src={about6}/>
        <p className="grid-item">Since May 2022, we have been working on Ale Abbey, our upcoming Monastery Brewery Tycoon. We are planning to make Ale Abbey relaxing, rewarding, and a good segue into actual brewing! We plan to release Ale Abbey around the first quarter of 2024.</p>

        <p className="grid-item">So far, we've worked as small agile teams that focus on finishing a game, restructuring, and moving on to the next! Regardless of how odd of a bunch we are, we aspire to be fabulous with one another and the entire gaming community.</p>
        <img className="grid-item about-image" src={about1}/>

        </AboutGrid>

      </AboutPageTitle>

      
    <OurGamesWrapper>
      <OurGamesPageTitle>
        <h2>Our Team</h2>
      </OurGamesPageTitle>
      <OurTeamWrapper>
        <OurTeamItem>
          <OurTeamImage src={emiliano} alt="logo" />
          <OurTeamText>Emiliano Pastorelli</OurTeamText>
          <OurTeamSubText>Team Lead<br/>Producer<br/>Lead Game Designer<br/>Programmer</OurTeamSubText>
        </OurTeamItem>
        <OurTeamItem>
          <OurTeamImage src={francesco} alt="logo" />
          <OurTeamText>Francesco Di Pietro</OurTeamText>
          <OurTeamSubText>Art Director<br/>Pixel Artist</OurTeamSubText>
        </OurTeamItem>
        <OurTeamItem>
          <OurTeamImage src={gavin} alt="logo" />
          <OurTeamText>Gavin Mills</OurTeamText>
          <OurTeamSubText>Programmer</OurTeamSubText>
        </OurTeamItem>
        <OurTeamItem>
          <OurTeamImage src={yannis} alt="logo" />
          <OurTeamText>Yannis Patras</OurTeamText>
          <OurTeamSubText>Community Manager<br/>Marketing</OurTeamSubText>
        </OurTeamItem>
        <OurTeamItem>
          <OurTeamImage src={alessandro} alt="logo" />
          <OurTeamText>Alessandro Cingolani</OurTeamText>
          <OurTeamSubText>Lead Programmer<br/>Game Designer</OurTeamSubText>
        </OurTeamItem>
        <OurTeamItem>
          <OurTeamImage src={raimo} alt="logo" />
          <OurTeamText>Raimo Peterson</OurTeamText>
          <OurTeamSubText>Pixel Artist</OurTeamSubText>
        </OurTeamItem>
      </OurTeamWrapper>
    </OurGamesWrapper>
{/* 
      <AboutImage>
        <GatsbyImage src={Data.avatar.childImageSharp.gatsbyImageData} alt="about" />
      </AboutImage>

      <AboutDetails>
        <h2>Hey there, what’s up?</h2>
        <p>
          RedQ Team is a creative agency specializing in building scalable,
          high-performance web & mobile application. Our main concern is
          creating more value into the application so that can help our
          customers to grow their business.
        </p>
        <p>
          RedQ Team is a creative agency specializing in building scalable,
          high-performance web & mobile application. Our main concern is
          creating more value into the application so that can help our
          customers to grow their business.
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails> */}
    </AboutWrapper>
  );
};

export default About;
