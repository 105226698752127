import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const AboutWrapper = styled.div`
  position: relative;
  padding: 30px 15px 0;
  @media (max-width: 990px) {
    padding: 70px 15px 30px;
  }
  @media (max-width: 575px) {
    padding: 60px 15px 0;
  }
  .about-image {
    max-height: 320px;
  }
`;

export const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas: "column-1 column-2" 
                        "column-3 column-4"
                        "column-5 column-6"
                        "column-7 column-8"
                        "column-9 column-10"
                        "column-11 column-12"
                        ;
  grid-column-gap: 12px;
  grid-row-gap: 0px;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
  text-align: center;
  .grid-item:nth-child(1) { grid-area: column-1 }
  .grid-item:nth-child(2) { grid-area: column-2 }
  .grid-item:nth-child(3) { grid-area: column-3 }
  .grid-item:nth-child(4) { grid-area: column-4 }
  .grid-item:nth-child(5) { grid-area: column-5 }
  .grid-item:nth-child(6) { grid-area: column-6 }
  .grid-item:nth-child(7) { grid-area: column-7 }
  .grid-item:nth-child(8) { grid-area: column-8 }
  .grid-item:nth-child(9) { grid-area: column-9 }
  .grid-item:nth-child(10) { grid-area: column-10 }
  .grid-item:nth-child(11) { grid-area: column-11 }
  .grid-item:nth-child(12) { grid-area: column-12 }
  @media ( max-width: 500px ) {  
    grid-template-columns: 1fr; 
    grid-template-areas: "column-1" 
                          "column-2"
                          "column-4"
                          "column-3"
                          "column-5"
                          "column-6"
                          "column-8"
                          "column-7"
                          "column-9"
                          "column-10"
                          "column-12"
                          "column-11"
                          ;
  }
`;

export const AboutPageTitle = styled.div`
  margin-bottom: 45px;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  p {
    // color: ${themeGet('colors.lightTextColor', '#757575')};
    font-size: 16px;
    color: #cecac3;
    margin-bottom: 2em;
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    color: ${themeGet('colors.textColor', '#292929')};
    line-height: 1.53;
    margin-bottom: 60px;
    text-align: center;

    @media (max-width: 990px) {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`;

export const AboutImage = styled.div`
  margin-bottom: 90px;
  @media (max-width: 990px) {
    margin-bottom: 60px;
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

export const AboutDetails = styled.div`
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;

  h2 {
    font-size: 21px;
    font-weight: 500;
    color: ${themeGet('colors.textColor', '#292929')};
    margin-bottom: 30px;
    @media (max-width: 990px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }

  p {
    color: ${themeGet('colors.lightTextColor', '#757575')};
  }
`;
export const SocialProfiles = styled.div`
  margin-top: 60px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 40px;
  }

  &:before {
    content: '';
    width: 30px;
    height: 1px;
    background: #292929;
    display: block;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;
