import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';

const PersonalBlogWrapper = styled.div`
  width: 100%;
`;



export const OurGamesButton = styled.div`
  margin: 0 auto;
  text-align: center;
`;

export default PersonalBlogWrapper;


export const OurTeamWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 990px) {
    display: grid;
    grid-template-columns: repeat(3, 150px);
    grid-template-rows: repeat(2, 240px);
    grid-gap: 50px 30px;
  }
  @media (max-width: 570px) {
    display: grid;
    grid-template-columns: repeat(2, 150px);
    grid-template-rows: repeat(2, 240px);
    grid-gap: 50px 30px;
  }
  @media (max-width: 460px) {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: none;
    grid-gap: 30px;
    justify-items: center;
  }
`;

export const OurTeamItem = styled.div`
  width: 140px;
  @media (max-width: 460px) {
    width: 180px;
  }
`;

export const OurTeamImage = styled.img`
  width: 100%;
  border-radius: 20%;
`;

export const OurTeamText = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-weight: bold;
  color: ${themeGet('colors.primaryText', '#292929')};
  white-space: nowrap;
`;

export const OurTeamSubText = styled.p`
  text-align: center;
  margin-top: -8px;
  color: ${themeGet('colors.primaryText', '#292929')};
  white-space: nowrap;
  margin-top: -1px;
  line-height: 1.4;
`;